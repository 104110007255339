<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.task_id, type: 'Documents|Orders|CitizenStatements.ResolutionTask', common: data.item.iscommon })"
            >
                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>
                
                <template v-slot:[`item.regnumber`]="{ item }">                    
                    <template v-if="item.ordertypeof === 3">
                        {{item.regnumber}}
                    </template>
                    <v-document-link v-else
                        :id="item.doc_id"
                        :type="'Chancellery|Documents.Document'"
                        :text="item.ordertypeof === 2 ? `${item.regnumber}//${item.actitemnumber}` : item.regnumber"
                        :common="item.iscommon"
                    />
                </template>

                <template v-slot:[`item.currentlimit`]="{ item }">
                    <div>{{dateFormat(item.currentlimit, 'DD.MM.YYYY')}}</div>
                </template>

                <template v-slot:[`item.signername`]="{ item }">
                    <v-employee-chip :id="item.signeremployeeid" :name="item.signername" />
                </template>
                
                <template v-slot:[`item.resolutiontext`]="{ item }">
                    <div class="elipsis-text-wrap">                        
                        <v-doc-type-chip :type="chipDoctype(item)" :text="chipText(item)"/>
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.resolutiontext">
                                {{item.resolutiontext}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.workstatus`]="{ item }">                    
                    <div class="onlyReadData more-per-lab-wrap">                                                
                        <StatusChip :status="avaibleStatuses[$helpers.getTaskStatus(item.workstatus)]"/>
                        <StatusChip v-if="item.chansendstatus" :status="avaibleStatuses[$options.rssStatusKeys[item.chansendstatus]]" />
                    </div>                    
                </template>

                <template v-slot:[`footer.prepend`]>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>
        </v-col>

    </v-row>
</template>

<script>
import i18n from '@/i18n'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import sys from "@/services/system"
import StatusChip from '@/components/StatusChip'

export default {
    columnIndex:  {
        'regnumber': 4,
        'resolutiontext': 5,
        'signername': 6,
        'currentlimit': 7
    },
    rssStatusKeys:["", "rssOnSign", "rssSignedByChief", "rssSended"],
    props: {
        status: {
            type: String,
            default: null
        },
        search: {
            type: String,
            default: ""
        }
    },
    components: {
        StatusChip
    },
    data () {
        return {
            loading: true,
            cancellationTokenSorce: null,
            headers: [
                { text: i18n.t('Номер_документа'), value: 'regnumber', width: '150px' },
                { text: i18n.t('Текст_задачи'), value: 'resolutiontext' },
                { text: i18n.t('Автор'), value: 'signername', width: '220px' },
                { text: i18n.t('Срок'), value: 'currentlimit', width: '150px', },
                { text: i18n.t('Статус'), value: 'workstatus', width: '200px', sortable: false }
            ],
            total: 0,            
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
        }
    },
    computed: {
        options: {
            get: function() {
                return this.$store.getters['tasks/myexecution/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('tasks/myexecution/SET_TABLE_OPTION', newValue);
            }
        },
        collection () {
            return this.$store.getters['tasks/getCollection'];
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search,
                OrderStatus: this.status,
                extendedFilterData: this.$helpers.getOnlySelectedParams(this.extendedTableFilter)
            }
        },
        extendedTableFilter() {
            return this.$store.getters['tasks/getExtendedFilter'];
        },
        avaibleStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        }
    },
    methods: {
        resetPage() {
            this.$store.commit('tasks/myexecution/SET_TABLE_OPTION_PAGE', 1);
        },
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;
            this.$emit("change_loaddata_status",this.loading);
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/tasks/list?collection=${this.collection}&filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;
            this.loading = false;
            this.$emit("change_loaddata_status",this.loading);
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, OrderStatus, extendedFilterData } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "currentlimit" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 7;
            return { SearchStr, OrderStatus, OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage, ...extendedFilterData };
        },
        dateFormat: sys.dateFormat,        
        chipDoctype(item)
        {
            switch (item.ordertypeof)
            {
                case 1:
                    return "Resolution";
                case 2:
                    return "ActItem";
                case 3:
                    return "Order";
                case 4:
                    return "IQalaOrder";

                default:
                    return "Resolution";
            }
        },
        chipText(item)
        {
            switch (item.ordertypeof)
            {
                case 1:
                    return i18n.t("Резолюция");
                case 2:
                    return i18n.t("Пункт_ОРД");
                case 3:
                    return i18n.t("Поручение");
                case 4:
                    return i18n.t("Наряд");

                default:
                    return i18n.t("Резолюция");
            }
        }
    },
    watch: {
        filter: {
            handler() {
                this.getData()
            },
            deep: true,
        },
    },
}
</script>
