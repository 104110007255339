<template>

    <div class="wp-row">
        <div class="flex-grids flex-item-middle">
            <div class="filter-title">{{$t("Фильтр")}}:</div>

            <div class="flex-item-expand status-panel status-panel-ef">
                    
                <template v-for="[objKey, value] of Object.entries(this.dataSource)">
                    <v-chip
                        v-if="value.value != null && !Array.isArray(value.value)"
                        :key="objKey"
                        class="ma-1"
                        color="#009c95"
                        text-color="white"
                        small
                        label
                        close
                        @click:close="chipClose(objKey)"
                    >
                        {{$t(value.label)}}: {{getViewValue(value)}}
                    </v-chip>
                    <v-chip
                        v-else-if="value.value != null && Array.isArray(value.value)"
                        v-for="(vitem) in value.value"
                        :key="objKey+vitem.id"
                        class="ma-1"
                        color="#009c95"
                        text-color="white"
                        small
                        label
                        close
                        @click:close="chipCloseItem(value.value, vitem)"
                    >
                        {{$t(value.label)}}: {{getViewValue(vitem)}}
                    </v-chip>
                </template>

                <v-btn
                    small
                    depressed
                    color="#f26877"
                    class="ma-1 text-capitalize white--text"
                    @click="onClearExFilter"
                >
                    {{$t("Очистить")}}
                </v-btn>

            </div>

        </div>
    </div>

</template>

<script>
export default {
    name: "ExtendedFilterPanel",
    props: {
        value: {
            type: Object,
            default: null
        }
    },
    computed: {
        dataSource : {
            get() {                
                return this.value;
            },
            set(val) {
                this.$emit('update:value', val);
            }
        }
    },
    methods: {
        chipClose(objKey) {
            let buffer = this.dataSource;
            buffer[objKey].value = null;
            this.dataSource = buffer;
        },
        chipCloseItem(array, key) {            
            var index = array.indexOf(key);
            if (index > -1) 
                array.splice(index, 1);
        },
        getViewValue(propertyValue) {            
            var value = propertyValue.value ?? this.$refLocale(propertyValue, this.$i18n.locale);

            if (typeof value !== 'object') {
                var dateMoment = this.$moment(value, 'YYYY-MM-DD', true);

                if (dateMoment != null && dateMoment.isValid())
                    return dateMoment.format('DD.MM.YYYY');
                
                return value;
            }
            else {
                if (Array.isArray(value)) {
                    //TODO ALL Case
                    return value[1];
                }
                else {
                    if (Object.prototype.hasOwnProperty.call(value, 'UniValue'))
                        return this.$refLocale(value, this.$i18n.locale);

                    return value.Value ?? value.value ?? value.name ?? "";
                }
            }            
        },
        onClearExFilter() {
            let copy = JSON.parse(JSON.stringify(this.dataSource));

            for (var property in copy) {
                if (Array.isArray(copy[property].value))
                    copy[property].value = [];
                else
                    copy[property].value = null;
            }

            this.dataSource = copy;
        }
    }
}
</script>